
import Vue from "@/interfaces/vue";
import { Component, Watch } from "vue-property-decorator";
import TherapyMessenger from "./TherapyMessenger.vue";
import { Therapy } from "@/interfaces/therapy";
import { DataDownloader, get } from "@/api";
import { Fragment } from "vue-fragment";
import i18n from "@/i18n";

Component.registerHooks(["beforeRouteEnter"]);
const pageKey = "messenger";

@Component({
  components: {
    TherapyMessenger,
    Fragment,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class MainTherapyMessenger extends Vue {
  therapies = new DataDownloader<Therapy[]>(() => get.therapies());
  interval!: NodeJS.Timeout;
  therapyId = +this.$route.params.therapyId;

  get pickedTherapy() {
    if (this.therapyId && this.therapies.data)
      return this.therapies.data.find((t) => t.id === this.therapyId);
    else return null;
  }

  mounted() {
    this.interval = setInterval(() => {
      this.therapies.update();
    }, 5000);
  }

  @Watch("$route")
  onRouteChange() {
    this.therapyId = +this.$route.params.therapyId;
  }

  destroyed() {
    clearInterval(this.interval);
  }
}
